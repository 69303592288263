import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class AjusteInventarioService {
 /*  async getClientesAll() {
    const clientes = await fetchWrapper.get(`${ruta}/cliente`);
    return clientes;
  }

  async sendClienteNuevo(cliente) {
    const nuevoCliente = await fetchWrapper.post(`${ruta}/cliente`, cliente);
    return nuevoCliente;
  }
  async deleteCliente(cliente) {
    const clienteEliminado = await fetchWrapper.delete(
      `${ruta}/cliente/` + cliente.id
    );
    return clienteEliminado;
  }
  async updatedCliente(cliente) {
    const clienteActualizado = await fetchWrapper.put(
      `${ruta}/cliente/` + cliente.id,
      cliente
    );
    return clienteActualizado;
  }
  async showCliente(cliente) {
    const clienteShow = await fetchWrapper.get(`${ruta}/cliente/` + cliente);
    return clienteShow;
  } */
  async saveAjusteInventario(datos){
    return await fetchWrapper.post(`${ruta}/ajuste_inventario`, datos);
  }
}